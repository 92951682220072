<template>
  <footer class="footer">
    © {{ startYear == year ? startYear : `${startYear} - ${year}` }} PT Lingkar Sembilan Titian Media
  </footer>
</template>
<script>
export default {
  data(){
    return {
      startYear: "2021",
      year: new Date().getFullYear()
    }
  } 
}
</script>